input.answer {
    width: 180px;
    height: 120px;
}

input.correct {
    background-color: seagreen;
}
input.incorrect {
    background-color: red;
}

.big {
    font-size: 110px;
}

.pl {
    padding-left: 15px;
}
.pr {
    right: 15px;
}

.inline {
    display: inline;
}